export const popularDirectionsMixin = {
    mounted() {
        this.getFaqAll();
    },
    data() {
        return {
            loading: false,
            form: {
                parent_id: null,
                title: null,
                subtitle: null,
                preview_title: null,
                attractions_title: null,
                search_title: null,
                slug: null,
                hidden_tag: [],
                detail_image: null,
                detail_image_delete: null,
                preview_image_square: null,
                preview_image_square_delete: null,
                preview_image_hr_rectangle: null,
                preview_image_hr_rectangle_delete: null,
                preview_image_vr_rectangle: null,
                preview_image_vr_rectangle_delete: null,
                description: null,
                published: false,
                sale_visible: false,
                order: 0,
                attractions: [],
                seo_title: null,
                seo_text: null,
                seo: this.getDefaultSeo(),
                faq_ids: null,
            },
            faq: [
                {
                    id: '1',
                    question: '2',
                },
            ],
            attractionOptions: [],
            parentOptions: [],
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
        };
    },
    methods: {
        getDefaultSeo() {
            return [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
                { code: 'query', value: '' },
            ];
        },
        async getFaqAll() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/faq-all`);
                this.faq = data.data;
            } catch (ex) {
                console.log('cannot fetch point edit params ' + ex);
            } finally {
                this.loading = false;
            }
        },
    },
};
